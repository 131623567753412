$q-primary: #009bdb;
$q-secondary: #1e50e2;
$q-gradient: linear-gradient(150deg, $q-primary 20%, $q-secondary 80%) !important;

$q-blue: #020230;
$q-red: #ff7474;
$q-yellow: #ffdd74;
$q-green: #74ff9f;
$q-indigo: #748bff;
$q-pink: #f074ff;

$odoo-primary: #714B67;
$odoo-secondary: #017e84;
$odoo-secondary-light: #0adfb4;
$odoo-primary-dark: #52374b;
$odoo-secondary-dark: #014e51;
$odoo-gradient: linear-gradient(150deg, $odoo-primary 20%, #503047 80%) !important;

$transition-time: 1.2s;